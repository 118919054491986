@import "./Theme.scss";
// .content-box {
//   // color: #eef0ff;
//   width: 100%;
//   height: min-content;
//   background-position: center;
//   background-size: cover;
//   background-repeat: no-repeat;
// }
// .img-shadow {
//   display: flex;
//   width: 100%;
  // height: max-content;
  // background-color: rgba(0, 0, 0, 0.7);
// }
.insight-intro {
  position: relative;
  min-height: calc(93vh - 42px);
  margin-top: 90px;
}

.insight-intro-div{
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
  height: '100%';
}

.grid-container-home #article_title {
  font-size: 40px;
  font-weight: 700;
  margin: 10px 0;
}

.grid-container-home #ESG_title {
  line-height: 100%;
  margin-bottom: 40px;
  font-size: 54px;
  font-weight: 900;
}

// .grid-container-home #article_content {
  // font-size: 16px;
  // font-family: "IBM Plex Serif", serif;
// }

.btn-general{
  border-radius: 5px !important;
  padding: 20px 30px !important;
  &:hover{
    transform: scale(1.1);
  }
}

.invertedColor {
  color: black;
}

// #article_content_link {
//   color: #eef0ff;
//   font-size: 16px;
//   // font-family: "Inter", sans-serif;
//   &:hover {
//     text-decoration: none;
//   }
// }

// #article_content_box {
//   line-height: normal;
//   // font-size: 16px;
//   // font-family: "IBM Plex Serif", serif;
// }


.highlights {
  // background-color: #ffffff5b;
  line-height: 100%;
  font-size: 36px;
  font-weight: 700;
  // font-family: "IBM Plex Serif", serif;
  display: block;
  width: 100%;
}

.environmentIcon {
  font-size: 110px;
}
.imageContact {
  background-image: url("../../assets/Contact Background.png");
  width: 50%;
  background-position: 0vh;
  background-size: cover;
  background-repeat: no-repeat;
}
.takePart {
  width: 60%;
  max-width: 700px;
  padding: 0 90px 0 60px;
  h2 {
    color: #002147;
    line-height: normal;
    font-weight: 700;
    font-size: 50px;
    margin: 50px 0;
  }
}

#sendMessageForm {
  // margin: 50px 0;
  // margin-bottom: 30px;
  .menuForm-input > input,
  textarea {
    font-size: 16px;
    margin-top: 1rem;
    min-height: 40px;
    padding: 10px 22px;
    border: none;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
  }
  .menuForm-button {
    margin-top: 1rem;
    font-size: 16px;
    width: 110px;
    color: #eef0ff;
    background-color: #97332f;
    border-radius: 50px;
    text-transform: capitalize;
    font-weight: 400;
  }
}

.m-scroll {
  color: #1c1b1f;
  overflow: hidden;
  padding: 15px 0;
  text-align: center;
  transition: all 2s ease;
  animation: scrollText 30s infinite linear;
}

@keyframes scrollText {
  from {
    transform: translateX(30%);
  }
  to {
    transform: translateX(-80%);
  }
}

@media (max-width: 912px) {
  .grid-container-home #article_title {
    padding: 0 14px 38px 14px;
    font-size: 30px;
    text-align: center;
  }

  .grid-container-home #ESG_title {
    font-size: 36px;
  }

  .highlights {
    font-size: 30px;
  }

  .imageContact {
    width: 0%;
  }
  .takePart {
    width: 100%;
    max-width: 900px;
    padding: 0 30px;
    h2 {
      font-size: 40px;
      margin: 0;
      margin-bottom: 30px;
    }
  }

  .m-scroll {
    color: #1c1b1f;
    overflow: hidden;
    padding: 10px 0;
    text-align: center;
    transition: all 2s ease;
    animation: scrollText 30s infinite linear;
  }

  // .imageE,
  // .imageE .img-shadow {
  //   height: 100%;
  // }
  // .imageS,
  // .imageS .img-shadow {
  //   height: 100%;
  // }
  // .imageG,
  // .imageG .img-shadow {
  //   height: 100%;
  // }

  .environmentIcon {
    font-size: 80px;
  }
}
