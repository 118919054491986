@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import './Theme';
* {
  box-sizing: border-box;
}

.grid-container {
  display: flex;
  height: max-content;
}

.grid-container-home {
  color: #eef0ff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
}

header {
  text-align: center;
}

#sidebar {
  // background-color: #eff6ff;
  //box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 1em;
  position: relative;
  color: #5f687b;
}

.sideForm {
  height: 100%;
  box-sizing: border-box;
  color: $palette-white-main;
}

#footer {
  font-size: 0.8rem;
  padding: .8rem 0;
  width: 100%;
  text-align: center;
  background-color: $palette-blue-medium;
}

.grid-container #edit_article {
  width: 100%;
  overflow: scroll;
  padding: 1rem 1rem 0 1rem;
  scroll-behavior: smooth;
}

.home-menu-dropdown {
  background-color: $palette-white-main;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 0 10px;
}

.MuiDataGrid-root
  .MuiDataGrid-columnHeaderCheckbox
  .MuiDataGrid-columnHeaderTitleContainer {
  display: none;
}

.background-dark {
  width: 100vw;
  height: 100vh;
  background-color: #00000088;
  position: fixed;
  z-index: 2;
}

.container-modal-ask-password {
  background-color: #eef0ff;
  border-radius: 0.5rem;
}

.scroll-y {
  overflow-y: scroll;
  height: 100%;
}

.merge-button {
  position: absolute;
  top: 85px;
  left: 800px;
}
