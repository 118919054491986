.form-settings {
    margin: 0 auto;
    
    background-color: rgba(255,255, 255, 0.7);
    padding: 30px;
}

.contenedor {
    margin-top: 80px;
}
/*
body {
    background-image: url('https://i.pinimg.com/originals/fa/a5/3d/faa53d318e455a9f2bf6a1fd990d50ad.jpg');
}*/

.sub-help {
    font-size: 12px;
    text-align: right;
    color: gray;
}