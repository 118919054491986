@import "./Theme.scss";

.nav-wrapper {
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.25);
  padding: 0;
}

.home-page {
  min-height: 90px;
  padding: 0 5% 0 5%;
}
.nav-container{
  max-width: 100% !important;
  width: 100%;
  height: 90px;
}

.nav-logo-img{
  height: 90px;
  object-fit: contain;
}

.nav-menu-toggle{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .25rem;
  border: 1px solid white;
  border-radius: 0;
  height: fit-content;
}

.navbar-collapse > a {
  color: #000;
  padding: 15px 20px;
  text-transform: uppercase;
  font-size: 0.9rem;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  transition: 0.3s all ease;
  //border-right: 1px solid #000;
  &:hover {
    background-color: #002147;
    color: #fff !important;
    text-decoration: none;
    /*border-bottom: 5px solid #2974D6;*/
  }
}

.link-active {
  background-color: $palette-primary-light !important;
  color: $palette-white-main;
  text-decoration: none;
}

.nav-group{
  &>a:not(:last-child){
    margin-right: calc( 8px / 2 );
  }
  &>a:not(:last-child){
    margin-right: calc( 8px / 2 );
  }
  &>a:not(:first-child){
    margin-left: calc( 8px / 2 );
  }
}

.dropdown-item a,
.dropdown-item a:hover,
.dropdown-item a:visited,
.dropdown-item a:active {
  color: black;
  text-decoration: none;
}

#basic-nav-dropdown {
  color: #000;
  font-size: 0.8rem;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  transition: 0.3s all ease;
  //border-right: 1px solid #000;
  &:hover {
    color: #002147;
    text-decoration: none;
    /*border-bottom: 5px solid #2974D6;*/
  }
}

.dropdown-item {
  font-size: 0.8rem;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #fff !important;
}

@media (min-width: 768px){
  .elementor-widget-container{
    width:  100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


@media only screen and (max-width: 1024px) {
  #responsive-navbar-nav {
    background-color: #d4e6fd;
    > a {
      display: block;
    }

    .dropdown-menu {
      width: 100%;
    }
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  #responsive-navbar-nav {
    height: 300px;
    overflow-y: scroll;
  }
}
