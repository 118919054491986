.recharts-wrapper {
  margin: 0 auto;
}

.con-query-links {
  padding: 0.5rem;
}

.query-link-active {
  cursor: pointer;
  color: blue;
}

.query-link-desactivate {
  color: gray;
}

.con-generic-input {
  margin: 0.5rem 0;
}

.con-financiallyMaterial-inputs {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
}

.title-input {
  font-size: 1.1rem;
  margin-bottom: 0.2rem;
}

.con-financiallyMaterial-radio-label {
  display: flex;
}

.bg {
  background-color: red;
  width: 100%;
  cursor: pointer;
}

.filters-stats {
  /*max-width: 1300px;*/
  width: 96%;
  display: flex;
  flex-wrap: wrap;
  margin: 15px auto 0 auto;
  padding: 25px 15px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  > * {
    margin-left: 15px;
  }

  input {
    font-size: 0.8rem;
  }
}

.custom-tooltip {
  background-color: rgba(255, 255, 255, 1);
  border: 1px #999 solid;
  font-size: 14px;
  padding: 1rem;
}

#tags-filled-label {
  margin: 0 20px;
  font-weight: 600;
}

.custom-tooltip .label {
  margin: 0;
  text-align: left;
}

.ESG_filters {
  position: absolute;
  left: 0;
}

.con-btn-vote-csv-with-chart {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.con-btn-vote-csv-without-chart {
  display: flex;
  justify-content: center;
  margin-top: 410px;
  width: 100%;
}

.btn-vote-csv {
  width: 200px;
}

.last-update-con {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
}

.parent-subject-dropdown {
  font-weight: 700;
  color: #373a3d;
}

@media (max-width: 612px) {
  .recharts-yAxis .recharts-text,
  .recharts-xAxis .recharts-text,
  .recharts-legend-wrapper .recharts-legend-item-text {
    font-size: 14px;
  }
}
