@import "./Theme.scss";
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
}

.modalVote {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: $palette-background-default;
  width: 80%;
  max-width: 1000px;
  max-height: 90vh;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
}

.modal-main-companies {
  width: 80vw;
  max-width: 1200px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.dropdown-subs {
  display: none;
}

.sub_funds li {
  list-style-type: none;
  display: inline-flex;
  align-items: flex-start;
  margin-left: 10px;
}

.sub_funds label {
  margin: 0px 5px;
}

/*
.sub_funds button {
  width: 100px;
  border: none;
}*/

.dropdown-btn-subfunds,
.funds-third-level-btn {
  width: 20px;
  margin: 0px 5px;
  padding: 0px;
}

.funds-third-level {
  display: none;
  margin-left: 20px;
}

.funds-third-level li {
  display: flex;
  align-items: baseline;
}

.funds-level-btn {
  width: 20px;
  height: 20px;
  min-width: 0px;
  padding: 0;
  font-size: 20px;
  line-height: 20px;
}

.sub_funds {
  margin-left: 20px;
  display: grid;
  row-gap: 5px;
}

.modal_close {
  position: absolute;
  top: 0px;
  right: 10px;
}

.funds-list-1 {
  columns: 1;
}

.funds-list-2 {
  height: 100%;
  columns: 2;
  column-gap: 0; 
}

.modal_close button {
  background-color: transparent;
  border: none;
  color: gray;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
}

.modal-buttons {
  padding-top: 20px;
  width: 100%;
  display: flex;
}

.button-continue {
  color: white;
  border: none;
  box-shadow: 0px 5px 25px rgba(0,0,0,0.25);
  border-radius: 50px;
  background-color: #002147;
  margin-right: 10px;
}

.button-continue:disabled {
  background-color: #0021478b;
}

.button-cancel {
  color: white;
  border: none;
  box-shadow: 0px 5px 25px rgba(0,0,0,0.25);
  border-radius: 50px;
  background-color: #5a6268;
}

.button-cancel:disabled {
  background-color: #5a62688b;
}

.names_companies_modal label {
  margin-bottom: 0;
}

.list-names {
  column-count: auto;
  column-width: 20rem;
}

.list-names li {
  overflow-x: hidden;
  list-style-type: none;
  align-items: baseline;
  display: flex;
  margin: 0.25rem 0;
}

.companies-list {
  font-size: 13px !important;
  padding: 0 !important;
}

.tab-content {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 90%;
}

.small-font {
  font-size: 11px;
}

.nav-tabs {
  margin-top: 20px;
  width: 100%;
  justify-content: center;
}

.nav-tabs > a {
  color: #5f687b;
  padding: 0 5px !important;
}

#uncontrolled-tab-example-tab-none {
  display: none !important;
}

.fundsFilters_form,
.companyFilters_form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  gap: 10px;
  flex-wrap: wrap;
  p {
    width: fit-content;
    font-size: 16px;
    margin: 0;
    min-width: 62px;
  }
  div {
    width: fit-content;
    margin: 0;
    min-width: 100px;
  }
}

.modal-not-companies-found-container {
  width: 100%;
  height: 30vh;
}

.icon-not-companies-found {
  font-size: 50px;
  margin-bottom: 5px;
}

.error-message {
  color: red;
  font-size: 14px;
  text-align: center;
}

.error-message-isin {
  color: red;
  font-size: 20px;
  text-align: center;
}

.edit_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 86%;
  background-color: $palette-background-default;
  box-shadow: 24;
  padding: 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  section {
    width: 100%;
    height: 100%;
  }
}

.edit_modal-content {
  position: static;
  height: 85%;
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
}

.form-check {
  width: min-content;
  > input {
    margin: 0;
    width: 50px;
    border: none;
  }
}
.form-buttons-box button {
  text-transform: none;
}
.form-buttons-box button:disabled {
  color: #eef0ff;
}

.page-link {
  padding: 5px 8px;
  color: #5f687b;
  text-decoration: none;
}
.pagination {
  color: #5f687b;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
}

.align-items-manager,
.align-items-cik,
.align-items-plan {
  flex-direction: column;
  list-style-type: none;
  align-items: flex-start;
  label {
    width: fit-content;
    display: inline;
  }
}

@media (max-width: 612px) {
  .edit_modal {
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100%;
    transform: none;
    h3 {
      font-size: 24px;
    }
  }

  .edit_modal-content {
    height: 90%;
  }

  .page-link {
    padding: 0 5px;
  }
  .pagination {
    padding: 0;
    margin: 10px -10px;
    justify-content: flex-start;
    a {
      font-size: 14px;
    }
    > button {
      margin: 0 !important;
      font-size: 12px;
      line-height: 1;
      padding: 5px;
      width: 20px;
    }
    .form-check {
      padding: 0;
      margin: 0;
      > input {
        margin-bottom: 8px;
      }
    }
  }

  .tab-content {
    font-size: 14px;
    height: 82%;
  }

  .companyFilters_form input,
  .form-control,
  p,
  .form-buttons-box button {
    font-size: 14px;
  }
  .form-buttons-box button {
    padding: 5px 10px;
    margin-top: 0px;
  }

  .companyFilters_form p {
    display: none;
  }
  .nav-tabs {
    margin-top: 5px;
  }

  .funds-list-2 {
    columns: 1;
  }

  .sub_funds li {
    margin-left: 0;
  }
}
