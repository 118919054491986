@import "./Theme.scss";

#mc_embed_shell {
  display: flex;
  justify-content: center;
  align-items: center;
}
#mc_embed_shell:focus-visible {
  border: none;
}
#mc_embed_signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: $palette-white-main;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0 10px 10px 0;
  max-width: 600px;
  margin: auto;
  text-align: center;

  @media (max-width: 480px) {
    border-radius: 10px;
  }
}

#mc_embed_signup h2 {
  font-size: 1.8rem;
  color: $palette-primary-main;
  margin-bottom: 1rem;
  @media (max-width: 480px) {
   font-size: 1.3rem;
  }
}

#mc_embed_signup p {
  font-size: 1rem;
  color: $palette-black-light;
  margin-bottom: 2rem;
  line-height: 1.5;
  @media (max-width: 480px) {
    font-size: 0.9rem;
   }
}

#mc-embedded-subscribe-form input[type="checkbox"] {
  display: inline;
  width: auto;
  margin-right: 10px;
}

#mc-embedded-subscribe-form .mc_fieldset {
  border: none;
  min-height: 0px;
  padding-bottom: 0px;
}

#mc-embedded-subscribe {
  margin-top: 20px;
  padding: 0.8rem 1.5rem;
  background: $palette-blue-light;
  color: $palette-white-main;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: darken($palette-blue-light, 20%);
  }
}

.indicates-required {
  font-size: 0.8rem;
  color: $palette-black-light;
  margin-top: 1rem;
}

.asterisk {
  color: $palette-red-light;
}

#mce-EMAIL {
  padding: 0.8rem;
  border: 1px solid $palette-gray-medium;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 1rem;
  font-size: 1rem;
  &:focus-visible {
    border: 1px solid $palette-red-light;
  }
}

.clear {
  display: flex;
  justify-content: flex-end;
}

.sf_container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

.sf_img_container {
  flex: 0 0 40%;
  background-image: url("../../assets/OxProx_Pattern_Red.svg");
  background-size: cover;
  background-position: center;
  border-radius: 10px 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 480px) {
    display: none;
  }
}

.sf_logo {
  width: 70%;
}
