@import "./Theme.scss";

.login-wrapper {
  width: 35%;
}

.login-box {
  background-color: $palette-background-default;
  backdrop-filter: blur(10px);
  top: 100px;
  width: 100%;
  box-shadow: 0 0 5px $palette-gray-medium;
  font-weight: 500;
  border-radius: 5px;
}

.login-body {
  width: 100vw;
  height: 100%;
  min-height: calc(100vh  - 90px - 70.8px);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .login-wrapper {
    width: 100%;
  }

  // .login-body {
  //   height: 95vh;
  // }
}
