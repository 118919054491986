@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  font-family: "Red Hat Text", sans-serif;
  /* background-color: #eef0ff !important; */
}

.robot {
  font-size: 150px;
  line-height: 100px;
}

.error404 {
  font-size: 80px;
}

th {
  text-align: center !important;
}

.proposal_table th {
  padding: 5px 0px;
}

.hidden {
  display: none;
}


/* Estilos generales para barras de desplazamiento en navegadores basados en WebKit (Chrome, Safari) */
/* ::-webkit-scrollbar { */
  /* width: 16.8px;  */
  /* Ancho de la barra de desplazamiento vertical */
  /* height: 16.8px;  */
  /* Altura de la barra de desplazamiento horizontal */
/* } */

/* ::-webkit-scrollbar-track { */
  /* Color de fondo de la barra de desplazamiento */
  /* background: #f1f1f1;  */
/* } */

/* ::-webkit-scrollbar-thumb { */
  /* Color de la barra de desplazamiento */
  /* background-color: #C1C1C1;  */
  /* Borde redondeado */
  /* border-radius: 10px;  */
  /* Bordes alrededor de la barra */
  /* border: 3px solid #f1f1f1;  */
/* } */

/* ::-webkit-scrollbar-thumb:hover { */
  /* background: #505050;  */
  /* Color de la barra de desplazamiento al pasar el mouse */
/* } */

/* ::-webkit-scrollbar-button:single-button { */
  /* Color de los botones */
  /* background-color: #C1C1C1; */

  /* display: block;
  height: 16px;
  width: 16px;
  border-radius: 0;
  border: none;
  cursor: pointer; */
/* } */

/* ::-webkit-scrollbar-button:single-button:vertical:decrement {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray"><polygon points="8,5 3,10 13,10"/></svg>') no-repeat center;
}

::-webkit-scrollbar-button:single-button:vertical:increment {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray"><polygon points="8,11 3,6 13,6"/></svg>') no-repeat center;
}

::-webkit-scrollbar-button:single-button:horizontal:decrement {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray"><polygon points="5,8 10,3 10,13"/></svg>') no-repeat center;
}

::-webkit-scrollbar-button:single-button:horizontal:increment {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray"><polygon points="11,8 6,3 6,13"/></svg>') no-repeat center;
} */
