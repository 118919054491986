@import "./Theme";
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


.input-error-message {
  color: red;
  position: absolute;
  font-size: 12px;
}

.form-title {
  display: inline;
}

.validation-error-msg {
  color: red;
  font-size: 14px;
  position: absolute;
}

.error-input {
  border: 1px solid red;
}

.error-input:focus {
  border-color: red;
  box-shadow: 0 0 0 0.2rem rgba(252, 0, 0, 0.25);
}

.valid-input {
  border: 1px solid #28a745;
}

.valid-input:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

#product-choice::-webkit-calendar-picker-indicator {
  opacity: 100;
}

.menuForm-label {
  font-size: 1rem;
  margin-bottom: 0px;
  margin-left: 5px;
  font-weight: 500;
}

.menuForm-input {
  margin-bottom: 15px;
}

.formInputMui {
  margin: 8px 0 !important;
  flex-direction: row !important;
  align-items: center;
  width: 90%;
  label {
    width: 40%;
    margin: 0;
  }
  > div {
    margin: 0 !important;
    padding: 5px 10px 0 10px;
    background-color: white;
    border-radius: 3px;
    font-weight: 600;
  }
}

.formInputDate{
    position: relative;
    margin: 0 !important;
    padding: 5px 10px 0 10px;
    background-color: white;
    border-radius: 3px;
    font-weight: 600;
    color: $palette-gray-medium;
    border:2px solid $palette-gray-medium;
    &::-webkit-calendar-picker-indicator {
      opacity: 0;
      cursor: pointer;
    }

  &::after {
    content: 'event';
    font-family: 'Material Icons';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $palette-gray-dark;
    pointer-events: none;
    z-index: 1;
  }
}

.menuForm-input .form-control {
  height: 3.25rem;
  font-weight: 600;
  border-radius: 3px 3px 0px 0px;
  border-bottom: 4px solid $palette-primary-light;
}

.menuForm-input textarea.form-control {
  height: auto;
}

.modal-company-width-auto {
  width: 250px !important;
  margin-left: 12px;
  margin-right: 12px;
}

.modal-company-width-small {
  width: 140px;
  margin-left: 10px;
  margin-right: 10px;
}

.modal-company-width-medium {
  width: 170px;
  margin-left: 10px;
  margin-right: 10px;
}

.small-field {
  width: 100px;
}

.form_xl {
  overflow: auto;
  max-height: 87%;
}

.form-buttons-box {
  display: flex;
  margin-top: 15px;
}

.form-buttons-box button {
  color: $palette-white-main;
  border: none;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  flex: 1 1 auto;
}

.form-buttons-box button:disabled {
  background-color: #b6b6b693;
}

.form-buttons-box button:first-of-type {
  background-color: $palette-primary-light;
  margin-right: 10px;
  &:not([disabled]):hover {
    background-color: darken($palette-primary-light, 20%);
  }
}

.form-buttons-box button:last-of-type {
  background-color: $palette-gray-medium;
  &:not([disabled]):hover {
    background-color: darken($palette-gray-medium, 20%);
  }
}

.form-buttons-box button:hover {
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.636);
}

.form-links{
  color: $palette-blue-light;
  &:hover{
    color: $palette-blue-dark ;
  }
}

.blue-input {
  border: none;
  border-bottom: 3px solid #002147;
  height: 38px;
  padding: 5px 20px 0 20px;
}

.blue-radio-button {
  border: 3px solid #002147;
  border-radius: 10px;
}

.advanced_search_btn {
  color: $palette-white-main;
  font-size: 1.1rem;
  text-decoration: underline;
  padding-bottom: 1rem;
}

//media queries landscape orientation for mobile devices
@media screen and (max-width: 912px) and (orientation: landscape) {
  .form_xl {
    max-height: 75%;
  }
}
