$palette-primary-main: #092044;
$palette-primary-light: #3c75b7;
$palette-secondary-main: #760f27;
$palette-secondary-light: #a52836;
$palette-black-light: #616161;
$palette-black-main: #000000;
$palette-blue-dark: #092044;
$palette-blue-medium: #363c46;
$palette-blue-light: #3c75b7;
$palette-blue-veryLight: #a1cbf2;
$palette-red-dark: #400815;
$palette-red-medium: #760f27;
$palette-red-light: #a52836;
$palette-gray-dark: #66625e;
$palette-gray-medium: #a59d97;
$palette-gray-light: #f3f1ee;
$palette-gray-extraLight: #E9E5E2;
$palette-white-main: #ffffff;
$palette-background-default: #f3f1ee;

$font-family-poppins: 'Poppins', sans-serif;
$font-family-goliat: 'Libre Baskerville', sans-serif;

.patternNavy {
  background-image: url("../../assets/OxProx_Pattern_Navy.svg");
}
.patternRed {
  background-image: url("../../assets/OxProx_Pattern_Red.svg");
}
.patternBeige {
  background-image: url("../../assets/OxProx_Pattern_Beige.svg");
}

.patternBlue {
  background-image: url("../../assets/OxProx_Pattern_Blue.svg");
}

.content-box {
  width: 100%;
  height: min-content;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.content-box-hero {
display: flex;
flex-wrap: wrap;

}
